import React from 'react';

function getColorClass(ratio) {
  if (ratio < 0.2) return 'text-red-500';
  if (ratio < 0.4) return 'text-orange-500';
  if (ratio < 0.6) return 'text-yellow-500';
  if (ratio < 0.8) return 'text-green-500';
  return 'text-green-700'; // Color para un resultado excelente
}


const CardHistoryItem = ({ title, image, aciertos, preguntas, fecha, lecture_id}) => {

    return (
      <div className="max-w-full w-full flex flex-wrap items-center border border-gray-200 bg-white rounded-xl shadow-md p-3 my-4 transition-transform transform hover:scale-105">
  <div className="flex items-center flex-grow min-w-0">
    <img
      src={image}
      alt={title}
      className="w-16 h-16 rounded-full border-2 border-blue-500 mr-4"
    />
    <div className="flex flex-col justify-between min-w-0">
      <div className="text-gray-900 font-bold text-lg truncate">
        {title}
      </div>
      <div className="text-gray-900 text-sm">Resultado:</div>
      <div className={`text-sm ${getColorClass(aciertos / preguntas)}`}>
        {`${aciertos} / ${preguntas}`}
      </div>
    </div>
  </div>
  <div className="flex flex-col items-end ml-auto text-sm text-gray-500">
    <div className="whitespace-nowrap">
      {fecha}
    </div>
    <a 
      href={`/lecturas/${lecture_id}`} 
      className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition duration-200 no-underline"
    >
      ¡Leer nuevamente!
    </a>
  </div>
</div>



    );
    return (
        <div className="max-w-full w-full flex items-center border border-gray-200 bg-white rounded-xl shadow-md p-1 my-1 transition-transform transform hover:scale-105">
          <div className="flex items-center">
            <img src={image} alt={title} className="w-16 h-16 rounded-full border-2 border-blue-500 mr-4" />
            <div className="flex flex-col justify-between">
              <div className="text-gray-900 font-bold text-lg">{title}</div>
              <div className="text-gray-900 text-sm">resultado:</div>
              <div className="text-gray-600 text-sm">{`${aciertos} / ${preguntas}`}</div>
            </div>
          </div>
          <div className="ml-auto text-gray-500 text-sm">
            {fecha}
          </div>
        </div>
      );
};

export default CardHistoryItem;
