import Cards from "../components/Cards"
export default function first({user, signOut}) {

    
    return <>
      
    <div className='App'>
    <div className="bg-cream-500 py-4 px-6">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          
        </div>
      </div>
    </div>

    <Cards grado_estudiante={user.attributes['custom:Grado']} categoria_usuario={user.attributes['custom:categoria_usuario']} username={user.username}/>
    </div> 
  </>
}