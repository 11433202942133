import { Amplify } from 'aws-amplify';
import { withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import {Sidemenu} from './components/sidemenu/Sidemenu.tsx'
import Home from './pages/Home';
import Error from './pages/Error';
import Lecturas from './pages/Lecturas'
import TestLectura from './pages/TestLectura';
import PublishLecture from './pages/PublishLecture.js';
import Profile from './pages/Profile';
import LecturaResult from './pages/LecturaResult';
import { Outlet } from "react-router-dom";
import Footer from './pages/Footer.js';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import { motion, useScroll, useSpring } from "framer-motion";
import Botones from './pages/botones.jsx';

import Myprogress from './components/progreso/Myprogress.js';
import GlobalProgress from './components/progreso/GlobalProgress.js';
import TopLectoresGlobal from './components/progreso/TopLectoresGlobal.js';
import EstadisticasLectores from './components/progreso/EstadisticasLectores.js';

import awsExports from './aws-exports'
Amplify.configure(awsExports)

function Layout({signOut,user}) {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });
return (
      <>
        <motion.div className="progress-bar-lecturas" style={{ scaleX }} />
        <Outlet />
        
        <Footer />
        
        <Sidemenu SignOut={signOut} username={user.attributes['preferred_username']===undefined?user.username:user.attributes['preferred_username']}/>
        
      </>
  );
}

function App({signOut, user}) {
  const router = createBrowserRouter([
    {
      element: <Layout signOut={signOut} user={user}/>,
      errorElement: <Error />,
      children: [{
        path: '/',
        element: <><Home user={user} signOut={signOut}/>  </> ,
      },
      {
        path: '/about',
        element: <Botones>About</Botones>
      },
      {
        path: '/publishLecture',
        element: <><br></br><PublishLecture user={user}/></> 
      },
      {
        path: '/profile',
        element: <Profile usernhalim={user}/>
      },
      {
        path: '/myprogress',
        element: <Myprogress user={user}/>
      },
      {
        path: '/globalprogress',
        element: <GlobalProgress user={user}/>
      },
      {
        path: '/globaltoplectores',
        element: <TopLectoresGlobal user={user}/>
      },
      {
        path: '/estadisticaslectores',
        element: <EstadisticasLectores user={user}/>
      },
      {
        path: '/inicio',
        element: <Sidemenu SignOut={signOut} username={user.username}/> 
      },
      {
        path: '/lecturas',
        element: <> <Lecturas user={user} signOut={signOut}/>  </> ,
        children: [{
          path: '/lecturas/:lecturaid',
          element: <TestLectura user={user} ></TestLectura>
        },{
          path: '/lecturas/resultado/:lecturaresultid/:points',
          element: <LecturaResult user={user} ></LecturaResult>
        }]
      }]
    }
  ])

  return (
        <RouterProvider router={router}></RouterProvider>   
       
  );
}
export default withAuthenticator(App)