import React from 'react'
import './cards.css'
import { motion, Variants } from "framer-motion";


import Botones from '../pages/botones';




const cardVariants: Variants = {
  offscreen: {
  },
  onscreen: {
    rotate: -360,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.2
    }
  }
};

function Card({title,imageSource,gradoestudiante,id, current_card, dificultadactualizada, flg}) {
  
  return (
    <motion.div
    className="card h-100 shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105"
    initial="offscreen"
    whileInView="onscreen"
    viewport={{ once: true, amount: 0.8 }}
  >
    <div className="overflow-hidden" style={{ height: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f8f8f8' }}>
      <img src={imageSource} className="card-img-top img-fluid" alt={title} style={{ height: '180px', objectFit: 'cover' }} />
    </div>
    <div className={"card-body " + flg}>
      <h5 className="card-title font-bold text-lg text-center">{title}</h5>
      <a href={`/lecturas/${id}`} 
        className="btn-custom mt-3 w-full text-center bg-green-500 text-white rounded-lg py-2 px-4 shadow-lg hover:bg-green-600 transition-transform transform hover:scale-105 block mx-auto no-underline">
        ¡Lee y aprende!
      </a>





    </div>
    <div className={"card-footer " + flg}>
      <div className="row text-center">
       
        <div className="col-12">
          <h6>#{current_card.tematica}</h6>
        </div>
        <div className="col-12">
          <small>Nivel: {current_card.nivel}</small>
        </div>
        <div className="col-12">
          <small>Nivel de Abstracción: {dificultadactualizada}</small>
        </div>
      </div>
    </div>
  </motion.div>

  );
  return (
        

<motion.div
      className="card"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
    >


<div className="overflow">
<img src={imageSource} className="card-img-top img-fluid" alt={title} ></img>
</div>
<div className={"card-body "+flg}>
  <div className="row">
    <h1>{title}</h1>
  </div>
  
</div>

<a href={`/lecturas/${id}`} className='btn btn-outline-dark rounded-2'>
¡Lee y aprende!
</a>
<div className={"card-footer "+flg}>
    <div className="container-fluid">
        <div className="row">
                <strong>{title}</strong>
        </div>
        <div className="row">
            <div className="col-12">
                <h6>#{current_card.tematica}</h6>
            </div>
            <div className="col-12">
                <small>nivel:{current_card.nivel}</small>
            </div>
            <div className="col-12">
                <small>Nivel de Abstracción: {dificultadactualizada}</small>
            </div>
            {/* <Botones></Botones> */}
            
            
        </div>
    </div>
    
</div>
</motion.div>
  )
}

export default Card