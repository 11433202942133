import Card from './Card.tsx'
import Fetchdatos from './Fetchdatos'
import CardHistoryItem from './progreso/Cardhistoryitem.js'
import './cards.css' 

const url = "https://2dmtiaujne.execute-api.us-east-2.amazonaws.com/demo/dynamodbmanager"
const inputdata = {
    method:'post',
    body: JSON.stringify({
      operation: 'read_v01',
    })
  }

  var url_leidas = "https://2dmtiaujne.execute-api.us-east-2.amazonaws.com/demo/dynamodbmanager/getlectureresultsbyuser?id="
  const inputdata_leidas = {}

function formatDateTime(datetimeString) {
const date = new Date(datetimeString);

// Options for formatting date and time
const options = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
};

return date.toLocaleDateString(undefined, options);
}

function calcularPuntajeYLecturas(da_leidas) {
    // Paso 1: Agrupar por lecture_id
    const groupedByLecture = da_leidas.reduce((acc, curr) => {
        const { lecture_id, cantidad_acertadas } = curr;
        if (!acc[lecture_id]) {
            acc[lecture_id] = [];
        }
        acc[lecture_id].push(cantidad_acertadas);
        return acc;
    }, {});

    // Paso 2: Calcular el promedio por lecture_id
    const averageByLecture = Object.values(groupedByLecture).map(aciertos => {
        const sum = aciertos.reduce((a, b) => a + b, 0);
        return sum / aciertos.length;
    });

    // Paso 3: Sumar los promedios para obtener el puntaje total
    const puntajeTotal = averageByLecture.reduce((a, b) => a + b, 0).toFixed(2);

    // Paso 4: Contar la cantidad de lecturas distintas (por lecture_id)
    const cantidadLecturasDistintas = Object.keys(groupedByLecture).length;

    // Retornar ambos valores
    return {
        puntajeTotal,
        cantidadLecturasDistintas
    };
}




function Cards({grado_estudiante,categoria_usuario,username}) {
    console.log(categoria_usuario)
    const data =  Fetchdatos(url,inputdata,'post')

    url_leidas = url_leidas + username
    const dataleida = Fetchdatos(url_leidas,inputdata_leidas,'get')
    console.log(dataleida)
    var da_leidas = []
    var transformedLectures = []
    try {
        da_leidas = JSON.parse(dataleida[0].body).Items
        var _transformedLectures = da_leidas;
        _transformedLectures.sort((a, b) => new Date(b.datetime_exam) - new Date(a.datetime_exam));
        transformedLectures = _transformedLectures
        console.log(username)
        console.log("jajajajaja")
        console.log(da_leidas)
        const resultado = calcularPuntajeYLecturas(da_leidas);
        var puntajeTotal = resultado.puntajeTotal
        var cantidadLecturasDistintas = resultado.cantidadLecturasDistintas
        console.log(resultado)
        
    } catch (error) {
        console.error(error.message);
    }

    var da = []

     function filter_lectures(gradodellalectura){
        var grado_estudiante_param = grado_estudiante
        if(grado_estudiante === undefined ){
            grado_estudiante_param = gradodellalectura['grado']
            //grado_estudiante_param = 3
        }

      
            
        if(gradodellalectura['active'] == false){
            console.log(gradodellalectura)
            return false
        }
        
        if(parseInt(gradodellalectura['grado'],10) >= 20){
            if (categoria_usuario.toLowerCase() == "docente") 
                return true
                //return (parseInt(gradodellalectura['grado'],10)-20 >= parseInt(grado_estudiante_param,10)-2) && (parseInt(gradodellalectura['grado'],10)-20 <= parseInt(grado_estudiante_param,10)+2)
            else 
                return false

        }else{
            if (categoria_usuario.toLowerCase() == "docente") 
                return (parseInt(gradodellalectura['grado'],10) >= parseInt(grado_estudiante_param,10)-3) && (parseInt(gradodellalectura['grado'],10) <= parseInt(grado_estudiante_param,10)+3)
            else 
                return (parseInt(gradodellalectura['grado'],10) >= parseInt(grado_estudiante_param,10)-2) && (parseInt(gradodellalectura['grado'],10) <= parseInt(grado_estudiante_param,10)+2)
        }
        
        
    } 

    function get_nivel_lectura( grado_estudiante_param, gradodellalectura){

        if(grado_estudiante_param === undefined )
            grado_estudiante_param = gradodellalectura

        var factordenivel = 0.6
        var nivellecturavisible = Math.round(Math.max(
                Math.min(
                    Math.exp((parseInt(gradodellalectura,10) - parseInt(grado_estudiante_param,10))*factordenivel),3
                ),1
            ))
        return nivellecturavisible
        
    }


    if (data != null ){
        console.log(data)
        da = JSON.parse(data[0].body).Items
        console.log("cantidad retornado")
        console.log(da)
        console.log(JSON.parse(data[0].body).LastEvaluatedKey) //caperucitarojayblancaaiversionperuana-97be994db84d4fd2a9ff9052bd694326
        console.log(JSON.parse(data[0].body))
        console.log("jejejeje")
        da = da.filter(filter_lectures)
    }


    if (!Array.isArray(data)){
        return(data)
    }
    else{

        
    return (
        <>

<div className="sticky top-0 z-1 bg-gradient-to-r from-green-400 to-blue-500 text-white p-4 shadow-lg">
  <div className="max-w-7xl mx-auto flex justify-between items-center">
    <div className="flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v4h1l.293-.293a1 1 0 011.414 0l2.586 2.586 1.293-1.293a1 1 0 011.414 0l7 7a1 1 0 010 1.414l-3 3a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414l1.293-1.293-2.586-2.586a1 1 0 010-1.414L9 8V7H5V3H3z" />
      </svg>
      <div className="text-xl font-bold">
        Mi Puntaje : <span className="text-3xl">{puntajeTotal}</span>
      </div>
    </div>
    <div className="flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 mr-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14M12 5l-7 7 7 7" />
      </svg>
      <div className="text-xl font-bold">
        Mis Lecturas Leidas: <span className="text-3xl">{cantidadLecturasDistintas}</span>
      </div>
    </div>
  </div>
</div>



<div className="flex flex-col md:flex-row w-full min-h-screen">

    <div className="w-full md:w-3/4 p-4 ">
    <h2 className="text-2xl font-bold mb-4">Lecturas Disponibles</h2>
    <div className="space-y-4">

            <div className="container mt-4 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 d-flex align-items-stretch">     
                <div className="card-deck row mt-4" id="product-container">
                {   
                    
                    
                    da.filter(lectura => 
                        !da_leidas.some(leida => leida.lecture_id === lectura.id)
                      ).map(card => {
                        try{
                            var nivelactualizado = get_nivel_lectura(grado_estudiante, card.grado)
                            var flarnaranjacard = ""
                            if(parseInt(card.grado,10) >= 20){
                                flarnaranjacard = "bg-warning"
                            }
                                return (
                                    <div id={`#${card.id}`} className="col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
                                            <Card  title={card.titulo} imageSource={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.id}.jpg`} content={''} id={card.id} current_card={card} dificultadactualizada={nivelactualizado} gradoestudiante={grado_estudiante} flg = {flarnaranjacard}/>
                                    </div>
                                )
                            /* }else{
                                return (
                                    <div  id={`#${card.id}`} className="col-lg-3 col-md-4 col-sm-4 col-12 mb-4 d-flex align-items-stretch">
                                            <Card  title={card.titulo} imageSource={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.id}.jpg`} content={''} id={card.id} current_card={card} dificultadactualizada={nivelactualizado} gradoestudiante={grado_estudiante}/>
                                    </div>
                                )
                            } */
                                
                        } 
                        catch{
                            console.log(JSON.stringify(card))
                            return <></>
                        }
                            
                            
                    })
                }
                </div>
            </div>
            </div>
            </div>

            <div className="w-full md:w-1/4 p-4 ">
                <h2 className="text-2xl font-bold mb-4">Lecturas Leídas</h2>
            <div className="space-y-4 max-h-screen overflow-y-auto">
            {
              
              transformedLectures.map(card => {
                return (
                    <CardHistoryItem
                    title={card.lecture_id.split("-")[0]}
                    image={`https://img-dinoblanco-shs.s3.amazonaws.com/album/${card.lecture_id}.jpg`}
                    aciertos={card.cantidad_acertadas}
                    preguntas={card.total_preguntas}
                    fecha={formatDateTime(card.datetime_exam)}
                    lecture_id = {card.lecture_id}
                    />
                )})
            }
                    </div>
                </div>
                </div>

        </>
    )}
}

export default Cards